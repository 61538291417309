<template>
  <v-container fluid style="margin-bottom: 100px">
    <div class="row">

      <div class="col-6 col-lg-3">
        <div class="card text-white primary elevation-5">
            <div class="card-body">
                <p class="card-title" v-if="dashboard.num_subMonth === 12">Order {{dashboard.subMonth}} {{ dashboard.subYear2 }}</p>
                <p class="card-title" v-else>ORDER {{dashboard.subMonth}} {{ dashboard.year }}</p>
                <p class="">{{dashboard.orderThisYearSubMonth ? dashboard.orderThisYearSubMonth.total : 0}} IDR <span class="badge text-warning">{{dashboard.orderPercentSub}} %</span> </p>
                <p class="">{{dashboard.countOrderThisYearSubMonth ? dashboard.countOrderThisYearSubMonth.total : 0}} Orders</p>
                <p class="">{{dashboard.wgtThisYearSubMonth ? dashboard.wgtThisYearSubMonth.total : 0}} Tons</p>
            </div>
                <div id="divider" style="background-color:#ffffff; height: 1px; width:100%;"></div>
            <div class="card-body">
                <p class="card-title">ORDER {{dashboard.subMonth}} {{ dashboard.subYear }}</p>
                <p class="">{{dashboard.orderSubYearSubMonth ? dashboard.orderSubYearSubMonth.total : 0}} IDR</p>
                <p class="">{{dashboard.countOrderSubYearSubMonth ? dashboard.countOrderSubYearSubMonth.total : 0}} Orders</p>
                <p class="">{{dashboard.wgtSubYearSubMonth ? dashboard.wgtSubYearSubMonth.total : 0}} Tons</p>
            </div>
        </div>
      </div>

      <div class="col-6 col-lg-3">
          <div class="card text-white primary elevation-5">
              <div class="card-body">
                  <p class="card-title">ORDER {{dashboard.month}} {{ dashboard.year }}</p>
                  <p class="">{{dashboard.orderThisYear ? dashboard.orderThisYear.total : 0}} IDR <span class="badge text-warning">{{dashboard.orderPercent}} %</span> </p>
                  <p class="">{{dashboard.countOrderThisYear ? dashboard.countOrderThisYear.total : 0}} Orders</p>
                  <p class="">{{dashboard.wgtThisYear ? dashboard.wgtThisYear.total : 0}} Tons</p>
              </div>
                  <div id="divider" style="background-color:#ffffff; height: 1px; width:100%;"></div>
              <div class="card-body">
                  <p class="card-title">ORDER {{dashboard.month}} {{ dashboard.subYear }}</p>
                  <p class="">{{dashboard.orderSubYear ? dashboard.orderSubYear.total : 0}} IDR</p>
                  <p class="">{{dashboard.countOrderSubYear ? dashboard.countOrderSubYear.total : 0}} Orders</p>
                  <p class="">{{dashboard.wgtSubYear ? dashboard.wgtSubYear.total : 0}} Tons</p>
              </div>
          </div>
      </div>

      <div class="col-6 col-lg-3">
          <div class="card text-white primary elevation-5">
              <div class="card-body">
                  <p class="card-title" v-if="dashboard.num_subMonth === 12">INVOICE {{dashboard.subMonth}} {{ dashboard.subYear }}</p>
                  <p class="card-title" v-else>INVOICE {{dashboard.subMonth}} {{ dashboard.year }}</p>
                  <p class="">{{dashboard.invThisYearSubMonth ? dashboard.invThisYearSubMonth.total : 0}} IDR</p>
                  <p class="">{{dashboard.countInvThisYearSubMonth ? dashboard.countInvThisYearSubMonth.total : 0}} Inv.</p>
                  <p class="">{{dashboard.wgtInvThisYearSubMonth ? dashboard.wgtInvThisYearSubMonth.total : 0}} Tons</p>
              </div>
                  <div id="divider" style="background-color:#ffffff; height: 1px; width:100%;"></div>
              <div class="card-body">
                  <p class="card-title">INVOICE {{dashboard.subMonth}} {{ dashboard.subYear }}</p>
                  <p class="">{{dashboard.invSubYearSubMonth ? dashboard.invSubYearSubMonth.total : 0}} IDR</p>
                  <p class="">{{dashboard.countInvSubYearSubMonth ? dashboard.countInvSubYearSubMonth.total : 0}} Inv.</p>
                  <p class="">{{dashboard.wgtInvSubYearSubMonth ? dashboard.wgtInvSubYearSubMonth.total : 0}} Tons</p>
              </div>
          </div>
      </div>

      <div class="col-6 col-lg-3">
          <div class="card text-white primary elevation-5">
              <div class="card-body">
                  <p class="card-title">INVOICE {{dashboard.month}} {{ dashboard.year }}</p>
                  <p class="">{{dashboard.invThisYear ? dashboard.invThisYear.total : 0}} IDR</p>
                  <p class="">{{dashboard.countInvThisYear ? dashboard.countInvThisYear.total : 0}} Inv.</p>
                  <p class="">{{dashboard.wgtInvThisYear ? dashboard.wgtInvThisYear.total : 0}} Tons</p>
              </div>
                  <div id="divider" style="background-color:#ffffff; height: 1px; width:100%;"></div>
              <div class="card-body">
                  <p class="card-title">INVOICE </p>
                  <p class="">{{dashboard.invSubYear ? dashboard.invSubYear.total : 0}} IDR</p>
                  <p class="">{{dashboard.countInvSubYear ? dashboard.countInvSubYear.total : 0}} Inv.</p>
                  <p class="">{{dashboard.wgtInvSubYear ? dashboard.wgtInvSubYear.total : 0}} Tons</p>
              </div>
          </div>
      </div>

      <!-- <v-col cols="12" xs="12" sm="12" md="3">
        <v-card
          class="mx-auto"
        >

          <v-list-item two-line style="padding-bottom:0px">
            <v-list-item-content style="padding: 1px 0;">
              <v-list-item-title class="text-h5">
                Order
              </v-list-item-title>
              <v-list-item-subtitle v-if="dashboard.num_subMonth === 12">{{dashboard.subMonth}} {{ dashboard.subYear2 }}</v-list-item-subtitle>
              <v-list-item-subtitle v-else>{{dashboard.subMonth}} {{ dashboard.year }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-text style="padding-top: 0px;padding-bottom: 0px;">
            <v-row align="center">
              <v-col
                class="text-h6"
                cols="8"
              >
                {{dashboard.orderThisYearSubMonth ? dashboard.orderThisYearSubMonth.total : 0}}
              </v-col>
              <v-col cols="4">
                <v-btn
                  color="success"
                  dark
                  v-if="dashboard.orderPercentSub > 0"
                >
                  {{dashboard.orderPercentSub}} %
                </v-btn>
                <v-btn
                  color="error"
                  dark
                  v-else
                >
                  {{dashboard.orderPercentSub}} %
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <v-list-item style="padding-top: 0px;padding-bottom:0px;max-height: 35px;">
            <v-list-item-icon>
              <v-icon>mdi-cart</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>{{dashboard.countOrderThisYearSubMonth ? dashboard.countOrderThisYearSubMonth.total : 0}} Orders</v-list-item-subtitle>
          </v-list-item>

          <v-list-item style="padding-top: 0px;padding-bottom:0px;max-height: 35px;">
            <v-list-item-icon>
              <v-icon>mdi-weight</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>{{dashboard.wgtThisYearSubMonth ? dashboard.wgtThisYearSubMonth.total : 0}} Tons</v-list-item-subtitle>
          </v-list-item>
        </v-card>
        <v-card
          class="mx-auto mt-1"
        >
          <v-list-item two-line style="padding-bottom:0px">
            <v-list-item-content style="padding: 1px 0;">
              <v-list-item-title class="text-h5">
                Order
              </v-list-item-title>
              <v-list-item-subtitle>{{dashboard.subMonth}} {{ dashboard.subYear }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-text style="padding-top: 0px;padding-bottom: 0px;">
            <v-row align="center">
              <v-col
                class="text-h6"
                cols="12"
              >
                {{dashboard.orderSubYearSubMonth ? dashboard.orderSubYearSubMonth.total : 0}}
              </v-col>
            </v-row>
          </v-card-text>

          <v-list-item style="padding-top: 0px;padding-bottom:0px;max-height: 35px;">
            <v-list-item-icon>
              <v-icon>mdi-cart</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>{{dashboard.countOrderSubYearSubMonth ? dashboard.countOrderSubYearSubMonth.total : 0}} Orders</v-list-item-subtitle>
          </v-list-item>

          <v-list-item style="padding-top: 0px;padding-bottom:0px;max-height: 35px;">
            <v-list-item-icon>
              <v-icon>mdi-weight</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>{{dashboard.wgtSubYearSubMonth ? dashboard.wgtSubYearSubMonth.total : 0}} Tons</v-list-item-subtitle>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="3">
        <v-card
          class="mx-auto"
        >
          <v-list-item two-line style="padding-bottom:0px">
            <v-list-item-content style="padding: 1px 0;">
              <v-list-item-title class="text-h5">
                Order
              </v-list-item-title>
              <v-list-item-subtitle>{{dashboard.month}} {{ dashboard.year }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-text style="padding-top: 0px;padding-bottom: 0px;">
            <v-row align="center">
              <v-col
                class="text-h6"
                cols="8"
              >
                {{dashboard.orderThisYear ? dashboard.orderThisYear.total : 0}}
              </v-col>
              <v-col cols="4">
                <v-btn
                  color="success"
                  dark
                  v-if="dashboard.orderPercent > 0"
                >
                  {{dashboard.orderPercent}} %
                </v-btn>
                <v-btn
                  color="error"
                  dark
                  v-else
                >
                  {{dashboard.orderPercent}} %
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <v-list-item style="padding-top: 0px;padding-bottom:0px;max-height: 35px;">
            <v-list-item-icon>
              <v-icon>mdi-cart</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>{{dashboard.countOrderThisYear ? dashboard.countOrderThisYear.total : 0}} Orders</v-list-item-subtitle>
          </v-list-item>

          <v-list-item style="padding-top: 0px;padding-bottom:0px;max-height: 35px;">
            <v-list-item-icon>
              <v-icon>mdi-weight</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>{{dashboard.wgtThisYear ? dashboard.wgtThisYear.total : 0}} Tons</v-list-item-subtitle>
          </v-list-item>
        </v-card>
        <v-card
          class="mx-auto mt-1"
        >
          <v-list-item two-line style="padding-bottom:0px">
            <v-list-item-content style="padding: 1px 0;">
              <v-list-item-title class="text-h5">
                Order
              </v-list-item-title>
              <v-list-item-subtitle>{{dashboard.month}} {{ dashboard.subYear }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-text style="padding-top: 0px;padding-bottom: 0px;">
            <v-row align="center">
              <v-col
                class="text-h6"
                cols="12"
              >
                {{dashboard.orderSubYear ? dashboard.orderSubYear.total : 0}}
              </v-col>
            </v-row>
          </v-card-text>

          <v-list-item style="padding-top: 0px;padding-bottom:0px;max-height: 35px;">
            <v-list-item-icon>
              <v-icon>mdi-cart</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>{{dashboard.countOrderSubYear ? dashboard.countOrderSubYear.total : 0}} Orders</v-list-item-subtitle>
          </v-list-item>

          <v-list-item style="padding-top: 0px;padding-bottom:0px;max-height: 35px;">
            <v-list-item-icon>
              <v-icon>mdi-weight</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>{{dashboard.wgtSubYear ? dashboard.wgtSubYear.total : 0}} Tons</v-list-item-subtitle>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3">
        <v-card
          class="mx-auto"
        >
          <v-list-item two-line style="padding-bottom:0px">
            <v-list-item-content style="padding: 1px 0;">
              <v-list-item-title class="text-h5">
                Invoice
              </v-list-item-title>
              <v-list-item-subtitle v-if="dashboard.num_subMonth === 12">{{dashboard.subMonth}} {{ dashboard.subYear }}</v-list-item-subtitle>
              <v-list-item-subtitle v-else>{{dashboard.subMonth}} {{ dashboard.year }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-text style="padding-top: 0px;padding-bottom: 0px;">
            <v-row align="center">
              <v-col
                class="text-h6"
                cols="12"
              >
                {{dashboard.invThisYearSubMonth ? dashboard.invThisYearSubMonth.total : 0}}
              </v-col>
            </v-row>
          </v-card-text>

          <v-list-item style="padding-top: 0px;padding-bottom:0px;max-height: 35px;">
            <v-list-item-icon>
              <v-icon>mdi-credit-card</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>{{dashboard.countInvThisYearSubMonth ? dashboard.countInvThisYearSubMonth.total : 0}} Inv</v-list-item-subtitle>
          </v-list-item>

          <v-list-item style="padding-top: 0px;padding-bottom:0px;max-height: 35px;">
            <v-list-item-icon>
              <v-icon>mdi-weight</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>{{dashboard.wgtInvThisYearSubMonth ? dashboard.wgtInvThisYearSubMonth.total : 0}} Tons</v-list-item-subtitle>
          </v-list-item>
        </v-card>
        <v-card
          class="mx-auto mt-2"
        >
          <v-list-item two-line style="padding-bottom:0px">
            <v-list-item-content style="padding: 1px 0;">
              <v-list-item-title class="text-h5">
                Invoice
              </v-list-item-title>
              <v-list-item-subtitle>{{dashboard.subMonth}} {{ dashboard.subYear }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-text style="padding-top: 0px;padding-bottom: 0px;">
            <v-row align="center">
              <v-col
                class="text-h6"
                cols="12"
              >
                {{dashboard.invSubYearSubMonth ? dashboard.invSubYearSubMonth.total : 0}}
              </v-col>
            </v-row>
          </v-card-text>

          <v-list-item style="padding-top: 0px;padding-bottom:0px;max-height: 35px;">
            <v-list-item-icon>
              <v-icon>mdi-credit-card</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>{{dashboard.countInvSubYearSubMonth ? dashboard.countInvSubYearSubMonth.total : 0}} Inv</v-list-item-subtitle>
          </v-list-item>

          <v-list-item style="padding-top: 0px;padding-bottom:0px;max-height: 35px;">
            <v-list-item-icon>
              <v-icon>mdi-weight</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>{{dashboard.wgtInvSubYearSubMonth ? dashboard.wgtInvSubYearSubMonth.total : 0}} Tons</v-list-item-subtitle>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3">
        <v-card
          class="mx-auto"
        >
          <v-list-item two-line style="padding-bottom:0px">
            <v-list-item-content style="padding: 1px 0;">
              <v-list-item-title class="text-h5">
                Invoice
              </v-list-item-title>
              <v-list-item-subtitle>{{dashboard.month}} {{ dashboard.year }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-text style="padding-top: 0px;padding-bottom: 0px;">
            <v-row align="center">
              <v-col
                class="text-h6"
                cols="12"
              >
                {{dashboard.invThisYear ? dashboard.invThisYear.total : 0}}
              </v-col>
            </v-row>
          </v-card-text>

          <v-list-item style="padding-top: 0px;padding-bottom:0px;max-height: 35px;">
            <v-list-item-icon>
              <v-icon>mdi-credit-card</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>{{dashboard.countInvThisYear ? dashboard.countInvThisYear.total : 0}} Inv</v-list-item-subtitle>
          </v-list-item>

          <v-list-item style="padding-top: 0px;padding-bottom:0px;max-height: 35px;">
            <v-list-item-icon>
              <v-icon>mdi-weight</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>{{dashboard.wgtInvThisYear ? dashboard.wgtInvThisYear.total : 0}} Tons</v-list-item-subtitle>
          </v-list-item>
        </v-card>
        <v-card
          class="mx-auto mt-2"
        >
          <v-list-item two-line style="padding-bottom:0px">
            <v-list-item-content style="padding: 1px 0;">
              <v-list-item-title class="text-h5">
                Invoice
              </v-list-item-title>
              <v-list-item-subtitle>{{dashboard.month}} {{ dashboard.subYear }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-text style="padding-top: 0px;padding-bottom: 0px;">
            <v-row align="center">
              <v-col
                class="text-h6"
                cols="12"
              >
                {{dashboard.invSubYear ? dashboard.invSubYear.total : 0}}
              </v-col>
            </v-row>
          </v-card-text>

          <v-list-item style="padding-top: 0px;padding-bottom:0px;max-height: 35px;">
            <v-list-item-icon>
              <v-icon>mdi-credit-card</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>{{dashboard.countInvSubYear ? dashboard.countInvSubYear.total : 0}} Inv</v-list-item-subtitle>
          </v-list-item>

          <v-list-item style="padding-top: 0px;padding-bottom:0px;max-height: 35px;">
            <v-list-item-icon>
              <v-icon>mdi-weight</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle>{{dashboard.wgtInvSubYear ? dashboard.wgtInvSubYear.total : 0}} Tons</v-list-item-subtitle>
          </v-list-item>
        </v-card>
      </v-col> -->
      <v-col cols="12">
        <v-card>
          <v-card-text>Monthly Order 
            <p style="padding: 0px 15px; font-size: 11px; font-style: italic;">K: thousand, M: million, B: billion</p>
            <v-row align="center">
              <v-col
                cols="12"
              >
              <div id="chartContainer1" style="height: 370px; width: 100%;"></div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12"
          xs="12" 
          sm="12" 
          md="4">
        <v-card>
          <v-card-text>Order Performance in <b> {{ subYear }} </b>
            <v-row align="center" class="mt-1">
              <v-col
                cols="12"
              >
                <div id="chartContainer2" style="height: 370px; width: 100%;"></div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12"
          xs="12" 
          sm="12" 
          md="4">
        <v-card>
          <v-card-text>Order Performance in <b> {{ year }} </b>
            <v-row align="center" class="mt-1">
              <v-col
                cols="12"
              >
                <div id="chartContainer3" style="height: 370px; width: 100%;"></div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12"
          xs="12" 
          sm="12" 
          md="4">
        <v-card>
          <v-card-text>Order Performance in <b> {{ month }} </b>
            <v-row align="center" class="mt-1">
              <v-col
                cols="12"
              >
                <div id="chartContainer4" style="height: 370px; width: 100%;"></div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </div>
  </v-container>
</template>
<script>
  
  export default {
    data() {
        return {
          display: 'none',
          snackbar: {
              color: null,
              icon: null,
              mode: null,
              position: "top",
              text: null,
              timeout: 7500,
              title: null,
              visible: false
          },
          labels: ['SU', 'MO', 'TU', 'WED', 'TH', 'FR', 'SA'],
          time: 0,
          forecast: [
            { day: 'Tuesday', icon: 'mdi-white-balance-sunny', temp: '24\xB0/12\xB0' },
            { day: 'Wednesday', icon: 'mdi-white-balance-sunny', temp: '22\xB0/14\xB0' },
            { day: 'Thursday', icon: 'mdi-cloud', temp: '25\xB0/15\xB0' },
          ],
          dashboard: {},
          monthlyOrder: {},
          dataPoints1:[],
          dataPoints2: [],
          dataPoints3: [],
          dataPoints4: [],
          dataPoints5: [],
          months: [
              'Jan', 'Feb', 'Mar', 'Apr', 'May',
              'Jun', 'Jul', 'Aug', 'Sep',
              'Oct', 'Nov', 'Dec'
          ],
          subYear: '',
          year: '',
          month: '',
          x: window.matchMedia("(max-width: 991px)")
        }
    },
    async mounted(){
        await axios.get(`${process.env.VUE_APP_URL}/api/kbt`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.dashboard = res.data
          this.subYear = res.data.subYear
          this.year = res.data.year
          this.month = res.data.month

          //console.log('ahaaa: ' + this.dashboard.num_subMonth);

        });

        await axios.get(`${process.env.VUE_APP_URL}/api/kbt/monthly-order`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.monthlyOrder = res.data

          this.dataPoints1 = [];
          for (var i = 0; i < this.monthlyOrder.last.length; i++) {
              this.dataPoints1.push({ label: this.monthNumToName(parseInt(this.monthlyOrder.last[i].month)), y: parseFloat(this.monthlyOrder.last[i].total) });
          }

          this.dataPoints2 = [];
          for (var i = 0; i < this.monthlyOrder.current.length; i++) {
              
              this.dataPoints2.push({ label: this.monthNumToName(parseInt(this.monthlyOrder.current[i].month)), y: parseFloat(this.monthlyOrder.current[i].total) });
          }

          this.getChart1(this.dataPoints1, this.dataPoints2)
        });

        await axios.get(`${process.env.VUE_APP_URL}/api/kbt/order-percent-last`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.dataPoints3 = [];
          for (var i = 0; i < res.data.length; i++) {
              
              this.dataPoints3.push({ label: res.data[i].category , y: parseFloat(res.data[i].total), legendText: res.data[i].category });
          }
          var container = 'chartContainer2';
          this.getChart2(this.dataPoints3, container)
        });

        await axios.get(`${process.env.VUE_APP_URL}/api/kbt/order-percent-current`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.dataPoints4 = [];
          for (var i = 0; i < res.data.length; i++) {
              
              this.dataPoints4.push({ label: res.data[i].category , y: parseFloat(res.data[i].total), legendText: res.data[i].category });
          }
          var container = 'chartContainer3';
          this.getChart2(this.dataPoints4, container)
        });

        await axios.get(`${process.env.VUE_APP_URL}/api/kbt/order-percent-month`, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          this.dataPoints5 = [];
          for (var i = 0; i < res.data.length; i++) {
              
              this.dataPoints5.push({ label: res.data[i].category , y: parseFloat(res.data[i].total), legendText: res.data[i].category });
          }
          var container = 'chartContainer4';
          this.getChart2(this.dataPoints5, container)
        });
    },
    methods:{
        monthNumToName(monthnum) {
          return this.months[monthnum - 1] || '';
        },
        addSymbols(e) {
          var suffixes = ["", "K", "M", "B"];
          var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

          if(order > suffixes.length - 1)
            order = suffixes.length - 1;

          var suffix = suffixes[order];
          return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
          if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
            e.dataSeries.visible = false;
          } else {
            e.dataSeries.visible = true;
          }
          e.chart.render();
        },
        toggleDataPointVisibility(e) {
          if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
            e.dataPoint.y = e.dataPoint.actualYValue;
            e.dataPoint.actualYValue = null;
            e.dataPoint.indexLabelFontSize = null;
            e.dataPoint.indexLabelLineThickness = null;
            e.dataPoint.legendMarkerType = "circle";
          } 
          else {
            e.dataPoint.actualYValue = e.dataPoint.y;
            e.dataPoint.y = 0;
            e.dataPoint.indexLabelFontSize = 0;
            e.dataPoint.indexLabelLineThickness = 0; 
            e.dataPoint.legendMarkerType = "cross";
          }
          e.chart.render();
        },
        showDefaultText(chart, text) {
          var dataPoints = chart.options.data[0].dataPoints;
          var isEmpty = !(dataPoints && dataPoints.length > 0);

          if (!isEmpty) {
            for (var i = 0; i < dataPoints.length; i++) {
              isEmpty = !dataPoints[i].y;
              if (!isEmpty)
                break;
            }
          }

          if (!chart.options.subtitles)
            chart.options.subtitles = [];
          if (isEmpty) {
            chart.options.subtitles.push({
              text: text,
              verticalAlign: 'center',
            });
            chart.options.data[0].showInLegend = false;
          } else {
            chart.options.data[0].showInLegend = false;
          }
        },
        getChart1(dataPoints1, dataPoints2){

          var chart1 = new CanvasJS.Chart("chartContainer1", {
              animationEnabled: true,
              theme: "light2",
              exportEnabled: true,
              axisY: {
                  crosshair: {
                      enabled: true,
                      snapToDataPoint: true
                  },
                  title: "IDR",
                  labelFormatter: this.addSymbols,
              },
              toolTip:{
              shared:true
            },
              legend: {
                  cursor: "pointer",
                  itemclick: this.toggleDataSeries
            },
              data: [
              {
                  type: "column",
                  indexLabel: "{y}",
                  indexLabelFontSize: 14,
                  indexLabelFontColor: "#FFF",
                  name: this.subYear + " Order",
                  showInLegend: true,
                  indexLabelPlacement: "inside",  
                  indexLabelOrientation: "horizontal",
                  color: "#ed6663",
                  yValueFormatString: "#,###,,,.##",
              },
              {
                  type: "column",
                  indexLabel: "{y}",
                  indexLabelFontSize: 14,
                  indexLabelFontColor: "#FFF",
                  name: this.year + " Order",
                  showInLegend: true,
                  indexLabelPlacement: "inside",  
                  indexLabelOrientation: "horizontal",
                  color: "#2d4059",
                  yValueFormatString: "#,###,,,.##",
              }]
          });

          chart1.options.data[0].dataPoints = dataPoints1;
          chart1.options.data[1].dataPoints = dataPoints2;

          if (this.x.matches) {

              for(var i = 0; i < chart1.options.data.length; i++){
                  chart1.options.data[i].indexLabelFontSize = 8;
              }
              chart1.render();
          }
          chart1.render();
      },
      getChart2(dataPoints, container){
          var pie_chart = new CanvasJS.Chart(container, {
            animationEnabled: true,
              exportEnabled: true,
              theme: "light2",
              exportEnabled: true,
              subtitles: [{
                  text: "in Million",		
                  fontColor: "green",
              }],
          legend: {
            itemclick: this.toggleDataPointVisibility
          },
              data: [{

                  type: "pie",
                  percentFormatString: "#0.##",
                  indexLabel: "{label} #percent%",
                  indexLabelFontSize: 12,
            showInLegend: true

              }]
          });
          pie_chart.options.data[0].dataPoints = dataPoints;
          this.showDefaultText(pie_chart, "No Data Found!");

          if (this.x.matches) {

              for(var i = 0; i < pie_chart.options.data.length; i++){
                  pie_chart.options.data[i].indexLabelFontSize = 6;
              }
              pie_chart.render();
          }
          pie_chart.render();
      }
    },
    watch: {
        option_items: {
            handler () {
                this.loading = true
                const { page, itemsPerPage } = this.option_items
            },
            deep: true,
        }
    }
  }
</script>

<style scoped>

.flex-container {
        display: flex;
        justify-content: space-between;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .container{
        margin-bottom: 60px !important;
    }

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        background: #a2d2ff !important;
        color: #fff;
    }

    .bgCustom2 {
        background: #bde0fe !important;
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }


</style>